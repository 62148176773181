exports.processZplForPrinting = (origin, shop, shippingType, sourceZpl) => {
    console.log("Processing zpl for: ", origin, shop, shippingType);

    let labelContent = sourceZpl;

    // BluEx
    if (shippingType === "Priority" || shippingType === "Normal") {
        labelContent = labelContent.replace(/\^LS0\n/g, "^LS-50^LT10\n");
    }
    // 99Minutos
    else if (shippingType === "99Min") {
        labelContent = labelContent.replace(/\^XA/, "^XA^LS-20^LT20");
    }
    // ML Colecta
    else if (shippingType === "Colecta") {
        labelContent = labelContent.replace(/\^XA/, "^XA^LS0");
    }
    // ML Flex
    else if (shippingType === "Flex") {
        labelContent = labelContent.replace(/\^XA/, "^XA^LS0");
    }
    else if (shop === "paris.cl") {
        labelContent = labelContent.replace(/\^LS0\n/g, "^LS-35^LT35\n");
    }
    else if (shop === "Ripley") {
        labelContent = labelContent.replace(/\^LS0\n/g, "^LS-35^LT50\n");
    }
    else if (shop === "Dafiti") {
        labelContent = labelContent.replace(/\^LS0\n/g, "^LS-55^LT50\n");
    }
    else if (shop === "FalabellaGlobalProduction") {
        labelContent = labelContent.replace(/\^LS0\n/g, "^LS-55^LT50\n");
    }
    // Cualquier otro
    else {
        labelContent = labelContent.replace(/\^XA/, "^XA^LS0");
    }

    return labelContent;
};
